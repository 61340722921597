import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import cx from "classnames";
import Image from "src/components/image";
import { PageLink } from "src/components/link";

import { getInternalLinkPath } from "src/utils/getInternalLinkPath";

import { ReactComponent as DevelopmentLogo } from "src/images/svg/development-header-logo.svg";
import { ReactComponent as Handicap } from "src/images/svg/handicap-icon.svg";
import { ReactComponent as EHO } from "src/images/svg/EHO-icon.svg";
import { ReactComponent as Logo } from "src/images/svg/fields-grade-footer-logo.svg";
import IronStateLogo from "src/images/Ironstate-logo-black-01.svg";

import styles from "./footer.module.css";

export const Footer = () => {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			sanityFooter {
				footerContent: _rawFooterContent(resolveReferences: { maxDepth: 10 })
			}
		}
	`);

	const {
		building,
		buildingLogo,
		buildingIllustration,
		mapLink,
		address,
		city,
		mapLink2,
		address2,
		city2,
		policyLink,
		finePrint,
	} = data.sanityFooter.footerContent;
	return (
		<>
			<footer className={styles.buildingFooter}>
				<div className={styles.buildingGraphics}>
					<div className={styles.addresses}>
						<div className={styles.addressContainer}>
							<div className={styles.mapLink}>
								{mapLink && (
									<a
										href={mapLink}
										target="_blank"
										rel="noopener noreferrer"
										className={cx("sans sans--caps-11 medium block")}
									>
										Map
									</a>
								)}
							</div>
							{address && (
								<p className={cx("sans sans--caps-11", styles.address)}>
									{address}
								</p>
							)}
						</div>
						<div className={styles.addressContainer}>
							<div className={styles.mapLink}>
								{mapLink2 && (
									<a
										href={mapLink2}
										target="_blank"
										rel="noopener noreferrer"
										className={cx("sans sans--caps-11 medium block")}
									>
										Map
									</a>
								)}
							</div>
							<p className={cx("sans sans--caps-11", styles.address)}>
								{address2}
							</p>
						</div>
					</div>
					{/* <div className={styles.buildingLogos}>
						<PageLink to="/">
							<DevelopmentLogo className={styles.developmentLogo} />
						</PageLink>
						<PageLink to={getInternalLinkPath(building)}>
							<Image
								className={styles.buildingLogo}
								alt={buildingLogo.alt}
								src={buildingLogo.asset.url}
							/>
						</PageLink>
					</div> */}
					<div className={styles.buildingIllustration}>
						<PageLink
							to={getInternalLinkPath(building)}
							className={styles.illustrationLink}
						>
							<Image imageId={buildingIllustration.asset._id} />
						</PageLink>
					</div>
				</div>
				{/* <div className={styles.buildingInfo}>
					{mapLink && (
						<a
							href={mapLink}
							target="_blank"
							rel="noopener noreferrer"
							className={cx("sans sans--caps-11 medium", styles.mapLink)}
						>
							Map
						</a>
					)}
					<p className={cx("sans sans--caps-11", styles.address)}>{address}</p>
					<span className={cx("serif serif--34 lower", styles.city)}>
						{city}
					</span>
				</div> */}
			</footer>
			<div className={styles.subfooter}>
				<div className={styles.footerColumn}>
					<a
						href="https://fieldsgrade.com/"
						target="_blank"
						rel="noopener noreferer"
					>
						<Logo className={styles.logo} />
					</a>
				</div>
				<div className={cx(styles.footerColumn, styles.center)}>
					<h4 className={cx("sans sans--caps-11 medium")}>Instagram</h4>
					<a
						className={cx("serif serif--36", styles.instagramLink)}
						href="https://www.instagram.com/fieldsgrade/"
						target="_blank"
						rel="noopener noreferer"
					>
						<span className="pink-underline-on-hover">@fieldsgrade</span>
					</a>
					<p className={cx("sans sans--caps-11", styles.copywrite)}>
						&copy; Fields Grade {new Date().getFullYear()}.
						{policyLink && (
							<Link
								className={styles.policyLink}
								to={getInternalLinkPath(policyLink.link.page)}
							>
								{policyLink.title}
							</Link>
						)}
					</p>
				</div>
				<div className={styles.footerColumn}>
					<div className={styles.columnInner}>
						<p className={styles.finePrint}>{finePrint}</p>
						<div className={styles.regulatoryIcons}>
							<img
								src={IronStateLogo}
								alt="Ironstate Logo"
								width="85"
							/>
							<Handicap />
							<EHO />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
