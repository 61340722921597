import React from "react";
import queryString from "query-string";
import cx from "classnames";
import axios from "axios";
import { Modal, Slide } from "@material-ui/core/";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
// @ts-ignore
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Backdrop } from "src/components/global/backdrop";
import {
	useSiteContext,
	useToggleContact,
	useCloseMenus,
} from "src/context/siteContext";

import { ReactComponent as CloseX } from "src/images/svg/x.svg";

import * as styles from "./contact.module.css";

// Entrata API vars
const entrataBaseURL = process.env.GATSBY_ENTRATA_API_URL;
const entrataUsername = process.env.GATSBY_ENTRATA_USERNAME;
const entrataPassword = process.env.GATSBY_ENTRATA_PASSWORD;

interface Window {
	gtag: Function;
	fbq: Function;
}

const TextInput = ({ label, children }: { label: string; children: any }) => {
	return (
		<div className={styles.textInputWrapper}>
			<label className={styles.label}>
				<span className={cx("sans sans--caps-11 medium", styles.labelCopy)}>
					{label}
				</span>
				{children}
			</label>
		</div>
	);
};

const Form = ({ toggle }: { toggle: () => void }) => {
	const { handleSubmit, register, errors, control } = useForm();

	const onSubmit = (data: any) => {
		// console.log(data);

		// fire google conversion event
		window?.gtag &&
			window.gtag("event", "conversion", {
				send_to: "AW-1029912699/RyhSCNvt-t4BEPvwjOsD",
			});

		// fire facebook pixel conversion event
		window?.fbq && window.fbq("track", "Lead");

		// prepare Entrata lead submission data
		const entrataRequestData = {
			auth: {
				type: "basic",
			},
			requestId: 15,
			method: {
				name: "sendLeads",
				params: {
					propertyId: "801561",
					prospects: {
						prospect: {
							leadSource: {
								originatingLeadSourceId: "95073",
							},
							createdDate: new Date().toLocaleDateString(),
							customers: {
								customer: {
									name: {
										firstName: data.firstName,
										lastName: data.lastName,
									},
									phone: {
										personalPhoneNumber: data.phone,
									},
									email: data.email,
								},
							},
							customerPreferences: {
								comment: data.comments,
							},
						},
					},
				},
			},
		};

		// submit to Entrata
		// axios(`${entrataBaseURL}/leads`, {
		// 	method: "post",
		// 	auth: {
		// 		username: entrataUsername as string,
		// 		password: entrataPassword as string,
		// 	},
		// 	data: entrataRequestData,
		// }).then(function(response) {
		// 	console.log(response);
		// });

		// prepare NWG data object
		const NWGDataObject = {
			formdata_name: `${data.firstName} ${data.lastName}`,
			formdata_email: data.email,
			formdata_phone: data.phone,
			formdata_comments: data.comments,
			formdata_source: sessionStorage.getItem("utm_source"),
			formdata_medium: sessionStorage.getItem("utm_medium"),
			formdata_campaign: sessionStorage.getItem("utm_campaign"),
			formdata_keywords: sessionStorage.getItem("utm_term"),
		};

		// convert to string
		const NWGDataString = queryString.stringify(NWGDataObject, { sort: false });

		// submit to NWG API endpoint
		axios
			.post(`https://nwgapi.com/apiforms/agnes/`, NWGDataString)
			.then((res) => {
				console.log(res);
			});

		// submit to mailchimp if opted in to marketing
		// if (data.subscribe) {
		addToMailchimp(data.email).then((mailchimpResponse: any) => {
			console.log(mailchimpResponse);
		});
		// }

		// close the registration drawer
		toggle();
	};

	return (
		<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.formFieldsContainer}>
				<div className={styles.personalDetailsColumn}>
					<TextInput label="First Name">
						<input
							className={cx("serif", styles.input)}
							name="firstName"
							type="text"
							required
							ref={register({
								required: "Required",
							})}
						/>
					</TextInput>
					<TextInput label="Last Name">
						<input
							className={cx("serif", styles.input)}
							name="lastName"
							type="text"
							required
							ref={register({
								required: "Required",
							})}
						/>
					</TextInput>
					<TextInput label="Email Address">
						<input
							className={cx("serif", styles.input)}
							name="email"
							type="email"
							required
							ref={register({
								required: "Required",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "invalid email address",
								},
							})}
						/>
					</TextInput>
					<TextInput label="Phone">
						<Controller
							as={NumberFormat}
							type="tel"
							format="(###) ###-####"
							mask=""
							name="phone"
							className={cx("serif", styles.input)}
							control={control}
						/>
					</TextInput>
				</div>
				<div className={styles.commentsColumn}>
					<textarea
						className={cx("serif", styles.textarea)}
						name="comments"
						ref={register}
						placeholder="comments"
						aria-label="Comments"
					/>
					{/* <div className={styles.checkboxWrapper}>
						<label className={cx("serif", styles.checkboxLabel)}>
							<input
								className={styles.checkboxInput}
								name="subscribe"
								type="checkbox"
								ref={register}
							/>
							<span className={cx("sans sans--caps-11 medium")}>
								Subscribe to our mailing list
							</span>
						</label>
					</div> */}
				</div>
			</div>
			<button
				className={cx(styles.submitButton)}
				type="submit"
				aria-label="Submit"
			>
				<span className="serif serif--60 lower">Submit</span>
			</button>
		</form>
	);
};

export const Contact = () => {
	const { contactIsOpen: open } = useSiteContext();
	const toggle = useToggleContact();
	const closeMenus = useCloseMenus();

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={toggle}
			closeAfterTransition={true}
			BackdropComponent={Backdrop}
			BackdropProps={{ active: open, handleClose: toggle }}
		>
			<Slide
				direction="down"
				in={open}
				timeout={{
					enter: 600,
					exit: 300,
				}}
			>
				<div className={styles.contact}>
					<button
						className={cx("corner-x", styles.closeButton)}
						onClick={toggle}
					>
						<CloseX />
					</button>
					<Form toggle={toggle} />
				</div>
			</Slide>
		</Modal>
	);
};
