import React, { useState, useRef } from "react";
import cx from "classnames";

import styles from "./cursor.module.css";

export const Cursor = () => {
	const cursorDot = useRef<HTMLDivElement>(null);

	const [cursorVisible, setCursorVisible] = useState(false);
	const [cursorActive, setCursorActive] = useState(false);

	const [cursor, setCursor] = useState("default");

	if (typeof window !== `undefined`) {
		const onMouseMove = (event: any) => {
			// Reveal cursor if hidden
			if (!cursorVisible) {
				setCursorVisible(true);
			}

			// Get cursor position
			const xPos = event.pageX - window.pageXOffset;
			const yPos = event.pageY - window.pageYOffset;

			requestAnimationFrame(() => {
				positionCursor(xPos, yPos);
			});
		};

		const onMouseOver = () => {
			const hoverEl = document.querySelector(
				"a:hover, button:hover, div[data-custom-cursor]:hover"
			);

			const hoverEls = Array.from(
				document.querySelectorAll(
					"a:hover, button:hover, div[data-custom-cursor] a:hover, div[data-custom-cursor] button:hover, div[data-custom-cursor]:hover"
				)
			);

			if (hoverEl && hoverEl.nodeName === "DIV") {
				const customCursor =
					hoverEl.getAttribute("data-custom-cursor") || "default";
				setCursor(customCursor);
			} else {
				setCursor("default");
			}

			if (
				hoverEls.some((el) => el.nodeName === "A" || el.nodeName === "BUTTON")
			) {
				setCursorActive(true);
			} else {
				setCursorActive(false);
			}
		};

		const onMouseEnter = () => {
			setCursorVisible(true);
		};

		const onMouseLeave = () => {
			setCursorVisible(false);
		};

		const onMouseDown = () => {
			setCursorActive(true);
		};

		const onMouseUp = () => {
			setCursorActive(false);
		};

		const positionCursor = (xPos: number, yPos: number) => {
			if (cursorDot.current) {
				cursorDot.current.style.top = yPos + "px";
				cursorDot.current.style.left = xPos + "px";
			}
		};

		// Bind event listeners
		document.addEventListener("mousemove", onMouseMove, { passive: true });
		document.addEventListener("mouseover", onMouseOver, { passive: true });
		document.addEventListener("mouseenter", onMouseEnter, { passive: true });
		document.addEventListener("mouseleave", onMouseLeave, { passive: true });
		document.addEventListener("mousedown", onMouseDown, { passive: true });
		document.addEventListener("mouseup", onMouseUp, { passive: true });
	}

	return (
		<div
			ref={cursorDot}
			className={cx(
				styles.cursor,
				styles[cursor],
				cursorVisible ? styles.visible : "",
				cursorActive ? styles.active : ""
			)}
		/>
	);
};
