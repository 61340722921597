import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import cx from "classnames";
import { PageLink } from "src/components/link";
import {
  useSiteContext,
  useToggleMainMenu,
  useToggleAvailabilityMenu,
  useToggleContact
} from "src/context/siteContext";

import { Hamburger } from "src/components/global/hamburger";

// import { ReactComponent as CartIcon } from "src/images/svg/cart-icon.svg";
import { ReactComponent as BuildingLogo } from "src/images/svg/the-agnes-header-logo.svg";
import { ReactComponent as DevelopmentLogo } from "src/images/svg/development-header-logo.svg";
import { ReactComponent as PhoneIcon } from "src/images/svg/phone-icon.svg";
import { ReactComponent as EnvelopeIcon } from "src/images/svg/envelope-icon.svg";

import styles from "./header.module.css";

export const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      sanityHeader {
        headerContent {
          contactPhoneNumber
          incentiveDesktop
        }
      }
    }
  `);

  const {
    contactPhoneNumber,
    incentiveDesktop
  } = data.sanityHeader.headerContent;

  const { mainMenuIsOpen, availabilityMenuIsOpen } = useSiteContext();
  const toggleMainMenu = useToggleMainMenu();
  const toggleAvailabilityMenu = useToggleAvailabilityMenu();
  const toggleContact = useToggleContact();

  return (
    <div className={cx(styles.header)}>
      <div className={styles.menuToggle}>
        <Hamburger open={mainMenuIsOpen} toggleMainMenu={toggleMainMenu} />
      </div>
      <div className={cx("sans sans--caps-11 medium", styles.incentive)}>
        {incentiveDesktop}
      </div>
      <div className={styles.headerInner}>
        <div>
          <a
            className={cx("sans sans--caps-11 medium", styles.residentLink)}
            onClick={() => toggleMainMenu()}
            href="https://ironstate.securecafe.com/residentservices/the-agnes/userlogin.aspx "
            target="_blank"
          >
            Resident Sign In
          </a>
        </div>
      </div>
      <div className={styles.headerRight}>
        {/* <div className={styles.cartToggleWrapper}>
					<button
						aria-label="cart"
						className={styles.cartToggle}
						onClick={() => toggleCart()}
					>
						<CartIcon />
					</button>
				</div> */}

        <button
          aria-label="Contact"
          className={cx("hide-min-medium", styles.contactToggle)}
          onClick={() => toggleContact()}
        >
          <EnvelopeIcon />
        </button>
        <div className={cx("hide-min-medium", styles.phoneLinkWrapper)}>
          <a
            href={`tel:${contactPhoneNumber}`}
            aria-label="Call"
            className={styles.phoneLink}
          >
            <PhoneIcon />
          </a>
        </div>
        <div className={styles.buildingLogo}>
          <PageLink
            to="/"
            onClick={mainMenuIsOpen ? () => toggleMainMenu() : null}
          >
            <BuildingLogo />
          </PageLink>
        </div>
        <div className={styles.developmentLogo}>
          <PageLink
            to="/"
            onClick={mainMenuIsOpen ? () => toggleMainMenu() : null}
          >
            <DevelopmentLogo />
          </PageLink>
        </div>
      </div>
    </div>
  );
};
