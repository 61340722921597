import React from "react";
import cx from "classnames";
import { Modal, Backdrop, Slide } from "@material-ui/core/";
import { PageLink } from "src/components/link";
import {
  useSiteContext,
  useToggleMainMenu,
  useToggleAvailabilityMenu,
  useCloseMenus,
  useToggleContact
} from "src/context/siteContext";

import { ReactComponent as HushMenuGraphic } from "src/images/svg/menu-hush.svg";
import { ReactComponent as HushMenuGraphicMobile } from "src/images/svg/menu-hush-mobile.svg";

import { ReactComponent as ArrowRight } from "src/images/svg/arrow-right-variable.svg";

import * as styles from "./main-menu.module.css";

export const MainMenu = () => {
  const { mainMenuIsOpen, availabilityMenuIsOpen } = useSiteContext();
  const toggleMainMenu = useToggleMainMenu();
  const toggleAvailabilityMenu = useToggleAvailabilityMenu();
  const closeMenus = useCloseMenus();
  const toggleContact = useToggleContact();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={mainMenuIsOpen}
      onClose={toggleMainMenu}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: {
          enter: 600,
          exit: 300
        }
      }}
    >
      <Slide
        direction="left"
        in={mainMenuIsOpen}
        timeout={{
          enter: 600,
          exit: 300
        }}
      >
        <div className={styles.mainMenuContainer}>
          <ul className={styles.mainMenu}>
            <li className={styles.mainMenuItem}>
              <PageLink
                to="/buildings/the-agnes"
                onClick={() => toggleMainMenu()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>The Apartments</span>
              </PageLink>
            </li>
            <li className={styles.mainMenuItem}>
              <PageLink
                to="/buildings/the-agnes#amenities"
                onClick={() => toggleMainMenu()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>The Amenities</span>
              </PageLink>
            </li>
            <li className={styles.mainMenuItem}>
              <PageLink
                to="/availability/the-agnes/all"
                onClick={() => toggleMainMenu()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>
                  Available Residences
                </span>
              </PageLink>
            </li>
            {/* <li className={styles.mainMenuItem}>
							<button
								onClick={() => toggleAvailabilityMenu()}
								className={cx(
									"serif serif--variable-medium lower",
									styles.mainMenuLink,
									styles.mainMenuLinkButton,
									mainMenuIsOpen ? styles.enter : ""
								)}
							>
								<div className={styles.mainMenuLinkArrow}>
									<ArrowRight />
								</div>
								<span className={styles.mainMenuLinkCopy}>
									Available Residences
								</span>
							</button>
						</li> */}
            <li className={styles.mainMenuItem}>
              <button
                onClick={() => toggleContact()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  styles.mainMenuLinkButton,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>Contact</span>
              </button>
            </li>
            {/* <li className={cx("hide-small hide-medium", styles.mainMenuItem)}>
							<PageLink
								to="/events"
								onClick={() => toggleMainMenu()}
								className={cx(
									"serif serif--variable-medium lower",
									styles.mainMenuLink,
									mainMenuIsOpen ? styles.enter : ""
								)}
							>
								<div className={styles.mainMenuLinkArrow}>
									<ArrowRight />
								</div>
								<span className={styles.mainMenuLinkCopy}>Events</span>
							</PageLink>
						</li> */}
            <li className={cx("hide-small hide-medium", styles.mainMenuItem)}>
              <PageLink
                to="/design-partners"
                onClick={() => toggleMainMenu()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>Design Partners</span>
              </PageLink>
            </li>
            {/* <li className={cx("hide-small hide-medium", styles.mainMenuItem)}>
							<PageLink
								to="/shop"
								onClick={() => toggleMainMenu()}
								className={cx(
									"serif serif--variable-medium lower",
									styles.mainMenuLink,
									mainMenuIsOpen ? styles.enter : ""
								)}
							>
								<div className={styles.mainMenuLinkArrow}>
									<ArrowRight />
								</div>
								<span className={styles.mainMenuLinkCopy}>Shop</span>
							</PageLink>
						</li> */}
            <li className={cx("hide-small hide-medium", styles.mainMenuItem)}>
              <a
                href="https://the-agnes-rentcafewebsite.securecafe.com/residentservices/the-agnes/userlogin.aspx"
                target="_blank"
                onClick={() => toggleMainMenu()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>
                  Resident Sign-In
                </span>
              </a>
            </li>
            <li className={styles.mainMenuItem}>
              <PageLink
                to="/coliving"
                onClick={() => toggleMainMenu()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>co-living</span>
              </PageLink>
            </li>
            <li className={styles.mainMenuItem}>
              <PageLink
                to="/hush"
                onClick={() => toggleMainMenu()}
                className={cx(
                  "serif serif--variable-medium lower",
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <div className={styles.mainMenuLinkArrow}>
                  <ArrowRight />
                </div>
                <span className={styles.mainMenuLinkCopy}>
                  <HushMenuGraphic className="hide-small hide-medium" />
                  <HushMenuGraphicMobile
                    className={cx(
                      "hide-min-large",
                      styles.hushMenuGraphicMobile
                    )}
                  />
                </span>
              </PageLink>
            </li>

            {/* <li
							className={cx(
								"hide-min-large",
								styles.mainMenuItem,
								styles.secondary
							)}
						>
							<PageLink
								to="/events"
								onClick={() => toggleMainMenu()}
								className={cx(
									styles.mainMenuLink,
									mainMenuIsOpen ? styles.enter : ""
								)}
							>
								<span className="sans sans--caps-14 medium">Events</span>
							</PageLink>
						</li> */}
            <li
              className={cx(
                "hide-min-large",
                styles.mainMenuItem,
                styles.secondary
              )}
            >
              <PageLink
                to="/design-partners"
                onClick={() => toggleMainMenu()}
                className={cx(
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <span className="sans sans--caps-14">Design Partners</span>
              </PageLink>
            </li>
            {/* <li
							className={cx(
								"hide-min-large",
								styles.mainMenuItem,
								styles.secondary
							)}
						>
							<PageLink
								to="/shop"
								onClick={() => toggleMainMenu()}
								className={cx(
									styles.mainMenuLink,
									mainMenuIsOpen ? styles.enter : ""
								)}
							>
								<span className="sans sans--caps-14 medium">Shop</span>
							</PageLink>
						</li> */}

            <li
              className={cx(
                "hide-min-large",
                styles.mainMenuItem,
                styles.secondary
              )}
            >
              <a
                href="https://ironstate.securecafe.com/residentservices/the-agnes/userlogin.aspx"
                target="_blank"
                onClick={() => toggleMainMenu()}
                className={cx(
                  styles.mainMenuLink,
                  mainMenuIsOpen ? styles.enter : ""
                )}
              >
                <span className="sans sans--caps-14">Resident Sign-In</span>
              </a>
            </li>
          </ul>
        </div>
      </Slide>
    </Modal>
  );
};
