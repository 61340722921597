import React from "react";
import cx from "classnames";
import { useCloseMenus } from "src/context/siteContext";
import styles from "./hamburger.module.css";

interface HamburgerProps {
	toggleMainMenu(): any;
	open: boolean;
}

export const Hamburger = ({ toggleMainMenu, open }: HamburgerProps) => {
	const closeMenus = useCloseMenus();
	return (
		<button
			onClick={open ? () => closeMenus() : () => toggleMainMenu(!open)}
			className={cx(
				styles.hamburger,
				styles.hamburgerSqueeze,
				open && styles.isActive
			)}
			type="button"
			aria-label="Toggle Main Menu"
			aria-haspopup="true"
			aria-expanded={open}
		>
			<span className={styles.hamburgerBox}>
				<span className={styles.hamburgerInner}></span>
			</span>
		</button>
	);
};
