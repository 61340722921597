/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import { SiteContextProvider } from "src/context/siteContext";
import Cookies from "js-cookie";

if (Cookies.get("hidePopup")) {
  Cookies.remove("hidePopup");
}

export const wrapRootElement = ({ element }) => (
  <SiteContextProvider>{element}</SiteContextProvider>
);

// Redirect logic in onRouteUpdate
// export const onRouteUpdate = ({ location }) => {
//   window.location.href = "https://ironstate.com/property/the-agnes/";
//   return; // Stop the execution
// };
