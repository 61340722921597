{
  /*
  Orginal Author of this file: https://github.com/thetrevorharmon
  Orginal File: https://github.com/thetrevorharmon/sell-things-fast/blob/master/src/context/SiteContext.js

  TYPED out by Issac: https://gist.github.com/isaac-martin

  Extended by Kevin Green for ✨
*/
}

import React, { useState, useEffect, useContext } from "react";
import cookie from "js-cookie";

interface SiteContextTypes {
  mainMenuIsOpen: boolean;
  availabilityMenuIsOpen: boolean;
  contactIsOpen: boolean;
  page: undefined;
}

const initialSiteContext = {
  mainMenuIsOpen: false,
  availabilityMenuIsOpen: false,
  contactIsOpen: false,
  page: undefined
};

const SiteContext = React.createContext({
  context: initialSiteContext,
  setContext: () => null
});

const SiteContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [context, setContext] = useState(initialSiteContext);

  return (
    <SiteContext.Provider
      value={{
        context,
        setContext
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

function useSiteContext() {
  const { context } = useContext(SiteContext);
  return context;
}

function useSetPage() {
  const {
    setContext
  }: {
    setContext: any;
  } = useContext(SiteContext);
  async function setPage(page: string) {
    setContext((prevState: SiteContextTypes) => {
      return { ...prevState, page };
    });
  }
  return setPage;
}

function useToggleContact() {
  const {
    context: { contactIsOpen },
    setContext
  }: {
    context: SiteContextTypes;
    setContext: any;
  } = useContext(SiteContext);

  function toggleContact() {
    setContext((prevState: SiteContextTypes) => {
      return { ...prevState, contactIsOpen: !contactIsOpen };
    });
  }

  return toggleContact;
}

function useToggleMainMenu() {
  const {
    context: { mainMenuIsOpen },
    setContext
  }: {
    context: SiteContextTypes;
    setContext: any;
  } = useContext(SiteContext);

  function toggleMainMenu() {
    setContext((prevState: SiteContextTypes) => {
      return { ...prevState, mainMenuIsOpen: !mainMenuIsOpen };
    });
  }

  return toggleMainMenu;
}

function useToggleAvailabilityMenu() {
  const {
    context: { availabilityMenuIsOpen },
    setContext
  }: {
    context: SiteContextTypes;
    setContext: any;
  } = useContext(SiteContext);

  function toggleAvailabilityMenu() {
    setContext((prevState: SiteContextTypes) => {
      return { ...prevState, availabilityMenuIsOpen: !availabilityMenuIsOpen };
    });
  }

  return toggleAvailabilityMenu;
}

function useCloseMenus() {
  const {
    setContext
  }: {
    setContext: any;
  } = useContext(SiteContext);

  function closeMenus() {
    setContext((prevState: SiteContextTypes) => {
      return {
        ...prevState,
        mainMenuIsOpen: false,
        availabilityMenuIsOpen: false
      };
    });
  }

  return closeMenus;
}

export {
  SiteContextProvider,
  useSiteContext,
  useSetPage,
  useToggleContact,
  useToggleMainMenu,
  useToggleAvailabilityMenu,
  useCloseMenus
};
