import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import queryString from "query-string";
import { useSiteContext } from "src/context/siteContext";
import { useWindowSize } from "src/hooks/useWindowHeight";
import { Header } from "src/components/global/header";
import { MainMenu } from "src/components/global/main-menu";
import { AvailabilityMenu } from "src/components/global/availability-menu";
import { Footer } from "src/components/global/footer";
import { Contact } from "src/components/global/contact";
import { Cursor } from "src/components/global/cursor";

import "src/styles/main.css";

const Layout = ({
  children,
  location
}: {
  children: any;
  location: { pathname: string; search: string };
}) => {
  const { page } = useSiteContext();
  const [enableFocusOutlines, setEnableFocusOutlines] = useState(false);
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    //redirect site away
    // window.location.href = "https://ironstate.com/property/the-agnes/";
    // return null;

    window && window.addEventListener("keydown", handleKeydown);

    // console.log(location.search);
    const parsedQueryString =
      location.search && queryString.parse(location.search);

    // console.log(parsedQueryString);
    for (const [key, value] of Object.entries(parsedQueryString)) {
      sessionStorage.setItem(key as string, value as string);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.body.style.setProperty("--vh", `${windowHeight * 0.01}px`);
    }
  }, [windowHeight]);

  const handleKeydown = (e: KeyboardEvent) => {
    // Detect a keyboard user from a tab key press
    const isTabEvent = e.keyCode === 9;

    if (isTabEvent) {
      setEnableFocusOutlines(true);
    }
  };

  //redirect site away
  // return null;

  return (
    <>
      <Helmet
        title="Fields Grade"
        htmlAttributes={{
          class: enableFocusOutlines ? "" : "no-outline-on-focus"
        }}
        bodyAttributes={{
          class: page
        }}
      />
      <Cursor />
      <div>
        <Header />
        <MainMenu />
        <AvailabilityMenu />
        <Contact />
        <div className="page-container">
          <div>{children}</div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
