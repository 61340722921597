module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fields-grade","short_name":"fields-grade","start_url":"/","background_color":"#E2DED4","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9cfd267cf8eacbfaf3f26efb082aa566"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/001/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-22513618-40","UA-22513618-41","AW-1029912699","G-LDE6406Y0G"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1455066174905526"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
