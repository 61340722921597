import React from "react";
import cx from "classnames";
import { Modal, Backdrop, Slide } from "@material-ui/core/";
import { PageLink } from "src/components/link";
import {
	useSiteContext,
	useToggleMainMenu,
	useToggleAvailabilityMenu,
	useCloseMenus,
} from "src/context/siteContext";

import { ReactComponent as ArrowRight } from "src/images/svg/arrow-right-variable.svg";

import * as styles from "./availability-menu.module.css";

export const AvailabilityMenu = () => {
	const { mainMenuIsOpen, availabilityMenuIsOpen } = useSiteContext();
	const toggleMainMenu = useToggleMainMenu();
	const toggleAvailabilityMenu = useToggleAvailabilityMenu();
	const closeMenus = useCloseMenus();

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={availabilityMenuIsOpen}
			onClose={toggleAvailabilityMenu}
			closeAfterTransition={true}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: {
					enter: 600,
					exit: 300,
				},
			}}
		>
			<Slide
				direction="left"
				in={availabilityMenuIsOpen}
				timeout={{
					enter: 600,
					exit: 300,
				}}
			>
				<ul className={styles.availabilityMenu}>
					<li className={styles.availabilityMenuItem}>
						<PageLink
							to="/availability/the-agnes/studio"
							onClick={() => closeMenus()}
							className={cx(
								"serif serif--variable-large lower",
								styles.availabilityMenuLink,
								availabilityMenuIsOpen ? styles.enter : ""
							)}
						>
							<div className={styles.availabilityMenuLinkArrow}>
								<ArrowRight />
							</div>
							<span className={styles.availabilityMenuLinkCopy}>Studio</span>
						</PageLink>
					</li>
					<li className={styles.availabilityMenuItem}>
						<PageLink
							to="/availability/the-agnes/1-bedroom"
							onClick={() => closeMenus()}
							className={cx(
								styles.availabilityMenuLink,
								availabilityMenuIsOpen ? styles.enter : ""
							)}
						>
							<div className={styles.availabilityMenuLinkArrow}>
								<ArrowRight />
							</div>
							<span className={styles.availabilityMenuLinkCopy}>
								<span className={cx("serif serif--variable-large lower")}>
									1
								</span>{" "}
								<span
									className={cx("sans sans--variable-large", styles.bedroom)}
								>
									{" "}
									Bedroom
								</span>
							</span>
						</PageLink>
					</li>
					<li className={styles.availabilityMenuItem}>
						<PageLink
							to="/availability/the-agnes/2-bedroom"
							onClick={() => closeMenus()}
							className={cx(
								styles.availabilityMenuLink,
								availabilityMenuIsOpen ? styles.enter : ""
							)}
						>
							<div className={styles.availabilityMenuLinkArrow}>
								<ArrowRight />
							</div>
							<span className={styles.availabilityMenuLinkCopy}>
								<span className={cx("serif serif--variable-large lower")}>
									2
								</span>{" "}
								<span
									className={cx("sans sans--variable-large", styles.bedroom)}
								>
									{" "}
									Bedroom
								</span>
							</span>
						</PageLink>
					</li>
					<li className={styles.availabilityMenuItem}>
						<PageLink
							to="/availability/the-agnes/3-bedroom"
							onClick={() => closeMenus()}
							className={cx(
								styles.availabilityMenuLink,
								availabilityMenuIsOpen ? styles.enter : ""
							)}
						>
							<div className={styles.availabilityMenuLinkArrow}>
								<ArrowRight />
							</div>
							<span className={styles.availabilityMenuLinkCopy}>
								<span className={cx("serif serif--variable-large lower")}>
									3
								</span>{" "}
								<span
									className={cx("sans sans--variable-large", styles.bedroom)}
								>
									{" "}
									Bedroom
								</span>
							</span>
						</PageLink>
					</li>
				</ul>
			</Slide>
		</Modal>
	);
};
