import cx from "classnames";
import Img from "gatsby-image/withIEPolyfill";
// @ts-ignore
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import React, { useState, useEffect } from "react";

const sanityConfig = {
	projectId: process.env.GATSBY_SANITY_PROJECT_ID,
	dataset: process.env.GATSBY_SANITY_DATASET,
};

export const Image = ({
	imageId,
	className,
	width,
	alt,
	src,
	style,
	imgStyle,
	objectFit,
	objectPosition,
	format,
}: {
	imageId?: string;
	width?: number;
	alt?: string;
	className?: string;
	src?: string;
	style?: object;
	imgStyle?: object;
	objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down" | undefined;
	objectPosition?: string;
	format?: string;
}) => {
	const [loaded, setLoaded] = useState(false);
	let fluidProps;

	if (imageId && !/gif/.test(imageId)) {
		fluidProps = getFluidGatsbyImage(
			imageId,
			{
				maxWidth: width || 2400,
				toFormat: format,
			},
			sanityConfig
		);
	}

	return (
		<figure className={cx(className)}>
			{fluidProps ? (
				<Img
					fluid={fluidProps}
					alt={alt}
					durationFadeIn={600}
					style={style}
					imgStyle={imgStyle}
					objectFit={objectFit}
					objectPosition={objectPosition}
				/>
			) : (
				<img
					alt={alt}
					src={src ? src : undefined}
					className={cx("x", {
						"is-loaded": loaded,
					})}
					onLoad={() => {
						setLoaded(true);
					}}
				/>
			)}
		</figure>
	);
};

export default Image;
